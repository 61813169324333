<template>
  <v-container fluid>
  
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModuls"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">
      <v-row align="center">
        <!-- Column Select Station -->
        <v-col class="col-input-filter">
          <v-select
            v-on:change="selectStation"
            :items="itemStation"
            item-value="station_id"
            item-text="name"
            label="Station"
            prepend-inner-icon="mdi-store-marker"
            solo
            dense
          ></v-select>
        </v-col>

        <!-- Column Select Calendar -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueFromDate"
                prefix="From :"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                v-bind="attrs"
                v-on="on"
                solo
                dense
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="valueFromDate"
              v-on:change="selectFromDate"
              @input="modalCalendar = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Select Calendar2 -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendar2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueToDate"
                prefix="To :"
                prepend-inner-icon="mdi-calendar-end"
                readonly
                v-bind="attrs"
                v-on="on"
                solo
                dense
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="valueToDate"
              v-on:change="selectToDate"
              @input="modalCalendar2 = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4">
          <v-btn
            class="white--text button mr-2 mb-5"
            solo
            dense
            normal
            :disabled="valueStation == null"
            @click="getExceedance()"
          >
            GENERATE REPORT
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              color="excel"
              class="mb-5"
              style="float: right;"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              @click="exportReport"
              :disabled="expIcon == null"
              >
                <v-icon
                color="white"
                center
                dark
                >
                    mdi-microsoft-excel
                </v-icon>
              </v-btn>
            </template>
            <span>Export to Excel</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              color="pdf"
              class="mb-5 mx-2"
              style="float: right;"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              @click="exportReport('pdf')"
              :disabled="expIcon == null"
              >
                <v-icon
                color="white"
                center
                dark
                >
                    mdi-file-pdf-box
                </v-icon>
              </v-btn>
            </template>
            <span>Export to PDF</span>
          </v-tooltip> -->
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
              <v-btn
              color="csv"
              class="mb-5 mx-2"
              style="float: right;"
              elevation="2"
              v-bind="attrs"
              v-on="on"
              @click="exportCSV()"
              :disabled="expIcon == null"
              >
                <v-icon
                color="white"
                center
                dark
                >
                    mdi-file-delimited
                </v-icon>
              </v-btn>
            </template>
            <span>Export to CSV</span>
          </v-tooltip>
          
        </v-col>
      </v-row>

      <v-card class="pa-4" style="max-height: calc(100vh - 230px);overflow-y:auto;">
        <v-subheader
          class="subTitles fontSubTitle-blue"
          v-if="queryValue == true"
        >
          <!-- EXCEEDANCE ALERT RECORD FOR {{ valueStation }} ({{ valueFromDate }} TO {{ valueToDate }}) -->
          Exceedance Alert Record For {{ valueStation }} ({{
            this.changeDateFormat(valueFromDate)
          }}
          TO {{ this.changeDateFormat(valueToDate) }})
        </v-subheader>

        <!-- Table -->
        <v-data-table
          :headers="thead_alertexceedance"
          :items="tbody_alertexceedance"
          :items-per-page="5"
          class="elevation-1 headerDtSarawak"
          :custom-sort="customSort"
          :loading="loadingTable"
        >

          <template v-slot:[`item.parameterSelected`]="{ item }">
            <div v-html="item.parameterSelected"></div>
          </template>

          <template v-slot:[`item.notes`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.notes"
              large
              persistent
              @save="saveExceed"
              @cancel="cancelExceed"
              @open="openExceed"
              @close="closeExceed"
            >
              <div>{{ props.item.notes }}</div>
              <template v-slot:input>
                <div class="mt-4 text-h6">
                  Update Notes
                </div>
                <v-text-field
                  v-model="props.item.notes"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                  @input="typingNotes(props.item,$event)"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>

        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}

          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>

      </v-card>
    </v-container>

  </v-container>
</template>

<script>
import axios from "axios";
// import XLSX from 'xlsx';
import Papa from "papaparse";

export default {
  data: () => ({
    pathModul: [
      {
        text: "Home",
        disabled: false,
        // href: "/",
        href: "/Mapviewer",
      },
      {
        text: "Alert - Exceedance Alert Record",
        disabled: true,
        href: "/Alert/Exceedance",
      },
    ],
    itemStation: [],
    // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    // date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    modalCalendar: false,
    modalCalendar2: false,
    expIcon: null,
    loadingTable: false,
    thead_alertexceedance: [
      {
        text: "STATION ID",
        value: "stationid",
        divider: true,
        align: "center",
        sortable: false,
      },
      { text: "DATETIME", value: "datetime", divider: true, align: "center" },
      {
        text: "EXCEEDANCE",
        value: "paramselected",
        divider: true,
        align: "center",
      },
      {
        text: "NOTES",
        value: "notes",
        divider: true,
        align: "center",
        sortable: false,
      },
      //   { text: 'UPDATED BY', value: 'notesBy', divider: true, align: 'center' },
      {
        text: "UPDATED ON",
        value: "notesupdateddt",
        divider: true,
        align: "center",
      },
    ],
    tbody_alertexceedance: [],

    //export excel
    thead_alertexceedanceReport: [
      {
        text: "STATION ID",
        value: "stationid",
        divider: true,
        align: "center",
        sortable: false,
      },
      { text: "DATETIME", value: "datetime", divider: true, align: "center" },
      {
        text: "EXCEEDANCE",
        value: "paramselected",
        divider: true,
        align: "center",
      },
      { text: "NOTES", value: "notes", divider: true, align: "center" },
      //   { text: 'UPDATED BY', value: 'notesBy', divider: true, align: 'center' },
      {
        text: "UPDATED ON",
        value: "notesupdateddt",
        divider: true,
        align: "center",
      },
    ],
    tbody_alertexceedanceReport: [],

    valueStation: null,
    valueFromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    valueToDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    queryValue: false,

    snack: false,
    snackColor: "",
    snackText: "",
    pagination: {},
    updateNotesExceed: {
      newId: "",
      notes: "",
    },
  }),

    methods: {

        selectStation(stationId){
            // console.log(stationId);
            this.valueStation = stationId;
        },

    selectFromDate(fromDate) {
      // console.log(fromDate);
      this.valueFromDate = fromDate;
    },

    selectToDate(toDate) {
      // console.log(toDate);
      this.valueToDate = toDate;
    },

    changeDateFormat(date) {
      var dd = date.split("-")[2];
      var mm = date.split("-")[1];
      var yyyy = date.split("-")[0];
      date = dd + "/" + mm + "/" + yyyy;
      return date;
    },

    changeDatetimeFormat(datetime) {
      if (datetime != "" && datetime != null) {
        let [date, time] = datetime.split("T");

        //convert date
        var dd = date.split("-")[2];
        var mm = date.split("-")[1];
        var yyyy = date.split("-")[0];
        date = dd + "/" + mm + "/" + yyyy;

        // Convert Time to 12 hours
        var H = +time.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = H < 12 ? "AM" : "PM";
        time = h + time.substr(2, 3) + time.substr(5, 3) + ampm;

        datetime = date + ", " + time;
        return datetime;
      } else {
        return (datetime = null);
      }
    },

        getStationExceedance(){
            axios.get(this.globalUrl+'mqm2/miqims/stations', {
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
            .then((response) => {

            let stationInfo = [
                {
                    station_id: 'all',
                    name: 'All Station'
                }
            ];

          response.data.forEach(function(res) {
            let objStation = {};
            objStation["station_id"] = res.STATION_ID;
            objStation["name"] = res.STATION_ID + " - " + res.LOCATION;
            stationInfo.push(objStation);
          });

          this.itemStation = stationInfo;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getExceedance() {
      this.loadingTable = true;
      this.expIcon = "1";
      this.tbody_alertexceedance = [];
      axios
        .get(
          this.globalUrl+"mqm2/miqims/exceedance?station=" +
            this.valueStation +
            "&startdt=" +
            this.valueFromDate +
            "&enddt=" +
            this.valueToDate,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {

          let data = response.data

          console.log(data);
          
          this.tbody_alertexceedance = data;
          this.loadingTable = false;

          // console.log(response.data[0]["datetime"], "aa")
          for (let i in response.data){
                          if (response.data[i]["datetime"].includes("T")) {
                            let dt = response.data[i]["datetime"].split("T")[0];
                            let t = response.data[i]["datetime"].split("T")[1];

                            let dt2 = dt.split("-")[2] + "/" + dt.split("-")[1] + "/" + dt.split("-")[0] 
                            // response.data[i]["datetime"] = response.data[i]["datetime"].split("T")[0] + " " + response.data[i]["datetime"].split("T")[1];
                            response.data[i]["datetime"] = dt2 + " " + t;
                        }
          }
          
        })
        .catch((error) => {
          console.log(error);
          this.loadingTable = false;
        });
    },

    exportCSV(){

      // console.log(this.tableCon)
      let headerExportCsv = [];
      for(let i in this.thead_alertexceedance){
        headerExportCsv.push(this.thead_alertexceedance[i]['value']);
      }

      let keys = headerExportCsv,
      resultAfterFilter = this.tbody_alertexceedance.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

      let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });

      let link = document.createElement("a");

      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'Alert - Exceedance Alert Record.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      },

    // open modal notes
    typingNotes(data,notes){

      // console.log(data);
      // console.log(notes);

      this.updateNotesExceed.newId = data.newid
      this.updateNotesExceed.notes = notes

      console.log(this.updateNotesExceed);

    },

    saveExceed() {
      const that = this;

      console.log(this.updateNotesExceed);

      axios
        .put(
          this.globalUrl+`mqm2/miqims/exceedance`,
          this.updateNotesExceed,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          that.getExceedance();
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(this.updateNotesExceed);
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancelExceed() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    openExceed() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    closeExceed() {
      console.log("Dialog closed");
    },

    exportReportExceed() {
      // const data = XLSX.utils.json_to_sheet(this.tbody_alertexceedance);
      // data['!cols'] = [];
      // data['!cols'][10] = { hidden: true };
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, data, 'data');
      // XLSX.writeFile(wb,'Exceedance Alert Record For ' + this.valueStation + ' (' + this.changeDateFormat(this.valueFromDate) + ' TO ' + this.changeDateFormat(this.valueToDate) + ').xlsx');
      // console.log(this.tbody_alertexceedance);
      let headerExceed = [];
      for (let i = 0; i < this.thead_alertexceedanceReport.length; i++) {
        headerExceed.push(this.thead_alertexceedanceReport[i].value);
      }

      let keysExceed = headerExceed,
        result = this.tbody_alertexceedanceReport.map((o) =>
          Object.assign(...keysExceed.map((k) => ({ [k]: o[k] })))
        );

      console.log(result);

      var blob = new Blob([Papa.unparse(result)], {
        type: "text/csv;charset=utf-8;",
      });

      var link = document.createElement("a");

      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        "Exceedance Alert Record For " +
          this.valueStation +
          " (" +
          this.changeDateFormat(this.valueFromDate) +
          " TO " +
          this.changeDateFormat(this.valueToDate) +
          ").csv"
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    exportReport(){


    // Define your style class template.

    let headerName = "<tr = 'border:none !important' ><td colspan ='12' style = 'text-align: center; border:none !important; font-weight: bold'>Exceedance Alert Record</td></tr>"
    var style = "<style> th, td { border: 10px solid;}</style>";

    var uri = 'data:application/vnd.ms-excel;base64,'
        , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' + style + '</head><body><table>' + headerName + '{table}</table></body></html>'
        , base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)))
        }
        , format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
        }

        let table = document.querySelectorAll('.v-data-table__wrapper ')
        console.log("hehe", table[0].innerHTML, table, document.querySelectorAll('.v-data-table__wrapper table'))
        let ctx = { worksheet: "Exceedance Alert Record" || 'Worksheet', table: table[0].innerHTML }
        // window.location.href = uri + base64(format(template, ctx))

        let a = document.createElement('a');
        a.href = uri + base64(format(template, ctx))
        a.download = 'Exceedance Alert Record.xls';
        //triggering the function
        a.click();
    },

    customSort: function(items, index, isDesc) {
      //   console.log(items, index, isDesc);

    //   console.log(index);

      items.sort((a, b) => {
        if (index[0] == "datetime" || index[0] == "notesUpdatedDt") {
          //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },


  },

  mounted() {
    this.getStationExceedance();
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.pathModuls {
  padding-bottom: 0px;
}

.pathModuls > li:nth-child(3) > a {
  color: black !important;
}

.container-filtering {
  padding: 0px;
}

.col-input-filter {
  padding: 0px 10px;
}

.col-btn-generateReport {
  margin-bottom: 18px;
}

.btn-generate {
  margin: 0px !important;
  // background: #4495D1 !important;
}

.container-filtering::v-deep .v-text-field.v-text-field--solo .v-input__control,
.btn-generate .v-btn .v-btn--contained {
  min-height: 10px;
  margin-top: 10px;
}

.subTitles {
  margin-left: 28%;
  // color: #1976D2 !important;
}

// .table-header {
//     white-space: nowrap;
//     padding: 16px !important;
//     // background: #4495D1;
//     // color: white !important;
//     text-align: center !important;
//     border: 1px solid rgba(0, 0, 0, 0.12);
// }
</style>